import * as types from '../actions';
import {getApi} from "../services/httpService";

export const loginUserAction = (user) => {
  return {
    type: types.LOGIN_USER,
    user: user
  }
};

export function setCurrentUser(user) {
  return {
    type: types.SET_CURRENT_USER,
    user: user
  }
}

export function unsetCurrentUser() {
  return {
    type: types.UNSET_CURRENT_USER
  }
}


export function login(data) {
  return getApi().post('user/login', data)
}


// export function authorizationcheck() {
//   let client_id = localStorage.getItem('F_CLIENT_ID');
//       client_id = client_id.substring(1, client_id.length - 1);
//         axios.get('userStatus/'+client_id).then(result => {
//           let idStatus = result.data.status; 
//           if (idStatus == 0) {
//             this.props.onLogout(e);
//           }
//         })
//       .catch(error => { console.error(error); 
//                         return Promise.reject(error); 
//               });
// }

