import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import rootReducer from '../reducers';
import rootSaga from '../sagas';

const configureStore = () => {
    const sagaMiddleware = createSagaMiddleware();

    const composeArgs = [
        applyMiddleware(sagaMiddleware),
    ];

    if(window && window.__REDUX_DEVTOOLS_EXTENSION__) {
        composeArgs.push(window.__REDUX_DEVTOOLS_EXTENSION__());
    }

    const store = createStore(
        rootReducer,
        compose.apply(undefined, composeArgs)
    );

    sagaMiddleware.run(rootSaga);

    return store;
};

export default configureStore;
