import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/stable';
// import 'react-app-polyfill/ie11'; // For IE 11 support
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux';
import configureStore from './helper/configureStore';
import {setAuthorizationToken} from "./services/httpService";
import {setCurrentUser} from "./actions/authenticationActions";
import jwt from 'jsonwebtoken';
import {LOGIN_TOKEN_NAME} from "./constants/appConstants";
import {config} from 'dotenv';
import "antd/dist/antd.css";

config();

const store = configureStore();

if (localStorage.getItem(LOGIN_TOKEN_NAME)) {
  setAuthorizationToken(localStorage.getItem(LOGIN_TOKEN_NAME));
  store.dispatch(setCurrentUser(jwt.decode(localStorage.getItem(LOGIN_TOKEN_NAME))));
}

ReactDOM.render(<Provider store={store}><App/></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
