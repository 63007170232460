import * as types from "../actions";

export default function userReducer(state={}, action) {
    const response = action.response;

    switch(action.type) {
        case types.USER_LIST_SUCCESS:
            return { ...state, response };
        case types.USER_LIST_ERROR:
            return { ...state, response };
        default:
            return state;
    }
}